//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PostSubmitMessage",
  data() {
    return { complaintPostSubmitMessage: null };
  },
  mounted() {
    this.complaintPostSubmitMessage = this.$cookies.get(
      "complaintPostSubmitMessage"
    );
    this.$cookies.remove("complaintPostSubmitMessage");

    if (this.$route.query.test_message) {
      this.complaintPostSubmitMessage = { complaintId: 42 };
    }
  },
};
