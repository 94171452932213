//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PostAdditionMessage",
  data() {
    return { show: false };
  },
  methods: {
    close() {
      this.show = false;
    },
  },
  mounted() {
    this.show = !!this.$cookies.get("objectAdditionSubmitted");
    this.$cookies.remove("objectAdditionSubmitted");
  },
};
