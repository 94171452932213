//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { call, get } from "vuex-pathify";
export default {
  computed: {
    selectedCity: get("settings/cityId"),
    cities: get("cities/list"),
  },
  methods: {
    select(city) {
      this.selectCity(city.id);
    },
    selectCity: call("settings/select"),
    confirm() {
      this.$emit("close");
    },
  },
};
