//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainFilter from "~/components/MainFilter.vue";
import CityList from "@/components/CityList.vue";
import { sync, get } from "vuex-pathify";

/* import Timeline from "~/components/Timeline.vue"; */

export default {
  name: "Sidebar",
  props: ["posts", "events"],
  data() {
    return {
      isMinified: false,
      /* cityList: false, */
      cities: [],
    };
  },
  computed: {
    cityList: sync("cities/openedList"),
  },
  components: {
    MainFilter,
    CityList,
    /* Timeline */
  },
  methods: {
    openList(event) {
      this.cityList = !this.cityList;
      this.cities = event;
    },
    close() {
      this.cityList = false;
    },
    sidebarMinify() {
      this.isMinified = !this.isMinified;
    },
  },
};
